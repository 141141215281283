
class Ballon {
    constructor(image, name, link){
      this._image = image;
      this._name = name;
      this._link = link;
    }
  }
  
  let baloons = []
  baloons.push(new Ballon('biuro_btn.png', 'biura', 'biura'))
  baloons.push(new Ballon('restauracje_btn.png', 'restauracje', 'restauracje'))
  baloons.push(new Ballon('sklepy_btn.png', 'sklepy', 'sklepy'))
  baloons.push(new Ballon('prywatne_btn.png', 'prywatne', 'prywatne'))
  // baloons.push(new Ballon('sklepy_tbn.png', 'medyczne', 'medyczne'))
  

  export default baloons