
export default {
  "biura": {
    "BWI": {
      "large": [
        "BWI_logo_large_1.png",
        "BWI_logo_large_10.png",
        "BWI_logo_large_2.png",
        "BWI_logo_large_3.png",
        "BWI_logo_large_4.png",
        "BWI_logo_large_5.png",
        "BWI_logo_large_6.png",
        "BWI_logo_large_7.png",
        "BWI_logo_large_8.png",
        "BWI_logo_large_9.png"
      ],
      "max": [
        "BWI_logo_max_1.png",
        "BWI_logo_max_10.png",
        "BWI_logo_max_2.png",
        "BWI_logo_max_3.png",
        "BWI_logo_max_4.png",
        "BWI_logo_max_5.png",
        "BWI_logo_max_6.png",
        "BWI_logo_max_7.png",
        "BWI_logo_max_8.png",
        "BWI_logo_max_9.png"
      ],
      "medium": [
        "BWI_logo_medium_1.png",
        "BWI_logo_medium_10.png",
        "BWI_logo_medium_2.png",
        "BWI_logo_medium_3.png",
        "BWI_logo_medium_4.png",
        "BWI_logo_medium_5.png",
        "BWI_logo_medium_6.png",
        "BWI_logo_medium_7.png",
        "BWI_logo_medium_8.png",
        "BWI_logo_medium_9.png"
      ],
      "mini": [
        "BWI_mini_1.png",
        "BWI_mini_10.png",
        "BWI_mini_2.png",
        "BWI_mini_3.png",
        "BWI_mini_4.png",
        "BWI_mini_5.png",
        "BWI_mini_6.png",
        "BWI_mini_7.png",
        "BWI_mini_8.png",
        "BWI_mini_9.png"
      ],
      "small": [
        "BWI_logo_small_1.png",
        "BWI_logo_small_10.png",
        "BWI_logo_small_2.png",
        "BWI_logo_small_3.png",
        "BWI_logo_small_4.png",
        "BWI_logo_small_5.png",
        "BWI_logo_small_6.png",
        "BWI_logo_small_7.png",
        "BWI_logo_small_8.png",
        "BWI_logo_small_9.png"
      ],
      "xl": [
        "BWI_logo_xl_1.png",
        "BWI_logo_xl_10.png",
        "BWI_logo_xl_2.png",
        "BWI_logo_xl_3.png",
        "BWI_logo_xl_4.png",
        "BWI_logo_xl_5.png",
        "BWI_logo_xl_6.png",
        "BWI_logo_xl_7.png",
        "BWI_logo_xl_8.png",
        "BWI_logo_xl_9.png"
      ],
      "description": ""
    },
    "Ellie May": {
      "large": [
        "Ellie_logo_large_1.png",
        "Ellie_logo_large_2.png",
        "Ellie_logo_large_3.png",
        "Ellie_logo_large_4.png",
        "Ellie_logo_large_5.png",
        "Ellie_logo_large_6.png",
        "Ellie_logo_large_7.png"
      ],
      "max": [
        "Ellie_logo_max_1.png",
        "Ellie_logo_max_2.png",
        "Ellie_logo_max_3.png",
        "Ellie_logo_max_4.png",
        "Ellie_logo_max_5.png",
        "Ellie_logo_max_6.png",
        "Ellie_logo_max_7.png"
      ],
      "medium": [
        "Ellie_logo_medium_1.png",
        "Ellie_logo_medium_2.png",
        "Ellie_logo_medium_3.png",
        "Ellie_logo_medium_4.png",
        "Ellie_logo_medium_5.png",
        "Ellie_logo_medium_6.png",
        "Ellie_logo_medium_7.png"
      ],
      "mini": [
        "Ellie_mini_1.png",
        "Ellie_mini_2.png",
        "Ellie_mini_3.png",
        "Ellie_mini_4.png",
        "Ellie_mini_5.png",
        "Ellie_mini_6.png",
        "Ellie_mini_7.png"
      ],
      "small": [
        "Ellie_logo_small_1.png",
        "Ellie_logo_small_2.png",
        "Ellie_logo_small_3.png",
        "Ellie_logo_small_4.png",
        "Ellie_logo_small_5.png",
        "Ellie_logo_small_6.png",
        "Ellie_logo_small_7.png"
      ],
      "xl": [
        "Ellie_logo_xl_1.png",
        "Ellie_logo_xl_2.png",
        "Ellie_logo_xl_3.png",
        "Ellie_logo_xl_4.png",
        "Ellie_logo_xl_5.png",
        "Ellie_logo_xl_6.png",
        "Ellie_logo_xl_7.png"
      ],
      "description": ""
    },
    "Nowy Styl Jasnogórska Office": {
      "large": [
        "NS2_logo_large_1.png",
        "NS2_logo_large_2.png",
        "NS2_logo_large_3.png",
        "NS2_logo_large_4.png",
        "NS2_logo_large_5.png",
        "NS2_logo_max_1.png",
        "NS2_logo_max_2.png",
        "NS2_logo_max_3.png",
        "NS2_logo_max_4.png",
        "NS2_logo_max_5.png"
      ],
      "max": [],
      "medium": [
        "NS2_logo_medium_1.png",
        "NS2_logo_medium_2.png",
        "NS2_logo_medium_3.png",
        "NS2_logo_medium_4.png",
        "NS2_logo_medium_5.png"
      ],
      "mini": [
        "NS2_mini_1.png",
        "NS2_mini_2.png",
        "NS2_mini_3.png",
        "NS2_mini_4.png",
        "NS2_mini_5.png"
      ],
      "small": [
        "NS2_logo_small_1.png",
        "NS2_logo_small_2.png",
        "NS2_logo_small_3.png",
        "NS2_logo_small_4.png",
        "NS2_logo_small_5.png"
      ],
      "xl": [
        "NS2_logo_xl_1.png",
        "NS2_logo_xl_2.png",
        "NS2_logo_xl_3.png",
        "NS2_logo_xl_4.png",
        "NS2_logo_xl_5.png"
      ],
      "description": ""
    },
    "Nowy Styl Jasnogórska Recepcja": {
      "large": [
        "NSJ_logo_large_1.png",
        "NSJ_logo_large_10.png",
        "NSJ_logo_large_11.png",
        "NSJ_logo_large_12.png",
        "NSJ_logo_large_13.png",
        "NSJ_logo_large_14.png",
        "NSJ_logo_large_15.png",
        "NSJ_logo_large_16.png",
        "NSJ_logo_large_17.png",
        "NSJ_logo_large_2.png",
        "NSJ_logo_large_3.png",
        "NSJ_logo_large_4.png",
        "NSJ_logo_large_5.png",
        "NSJ_logo_large_6.png",
        "NSJ_logo_large_7.png",
        "NSJ_logo_large_8.png",
        "NSJ_logo_large_9.png"
      ],
      "max": [
        "NSJ_logo_max_1.png",
        "NSJ_logo_max_10.png",
        "NSJ_logo_max_11.png",
        "NSJ_logo_max_12.png",
        "NSJ_logo_max_13.png",
        "NSJ_logo_max_14.png",
        "NSJ_logo_max_15.png",
        "NSJ_logo_max_16.png",
        "NSJ_logo_max_17.png",
        "NSJ_logo_max_2.png",
        "NSJ_logo_max_3.png",
        "NSJ_logo_max_4.png",
        "NSJ_logo_max_5.png",
        "NSJ_logo_max_6.png",
        "NSJ_logo_max_7.png",
        "NSJ_logo_max_8.png",
        "NSJ_logo_max_9.png"
      ],
      "medium": [
        "NSJ_logo_medium_1.png",
        "NSJ_logo_medium_10.png",
        "NSJ_logo_medium_11.png",
        "NSJ_logo_medium_12.png",
        "NSJ_logo_medium_13.png",
        "NSJ_logo_medium_14.png",
        "NSJ_logo_medium_15.png",
        "NSJ_logo_medium_16.png",
        "NSJ_logo_medium_17.png",
        "NSJ_logo_medium_2.png",
        "NSJ_logo_medium_3.png",
        "NSJ_logo_medium_4.png",
        "NSJ_logo_medium_5.png",
        "NSJ_logo_medium_6.png",
        "NSJ_logo_medium_7.png",
        "NSJ_logo_medium_8.png",
        "NSJ_logo_medium_9.png"
      ],
      "mini": [
        "NSJ_mini_1.png",
        "NSJ_mini_10.png",
        "NSJ_mini_11.png",
        "NSJ_mini_12.png",
        "NSJ_mini_13.png",
        "NSJ_mini_14.png",
        "NSJ_mini_15.png",
        "NSJ_mini_16.png",
        "NSJ_mini_17.png",
        "NSJ_mini_2.png",
        "NSJ_mini_3.png",
        "NSJ_mini_4.png",
        "NSJ_mini_5.png",
        "NSJ_mini_6.png",
        "NSJ_mini_7.png",
        "NSJ_mini_8.png",
        "NSJ_mini_9.png"
      ],
      "small": [
        "NSJ_logo_small_1.png",
        "NSJ_logo_small_10.png",
        "NSJ_logo_small_11.png",
        "NSJ_logo_small_12.png",
        "NSJ_logo_small_13.png",
        "NSJ_logo_small_14.png",
        "NSJ_logo_small_15.png",
        "NSJ_logo_small_16.png",
        "NSJ_logo_small_17.png",
        "NSJ_logo_small_2.png",
        "NSJ_logo_small_3.png",
        "NSJ_logo_small_4.png",
        "NSJ_logo_small_5.png",
        "NSJ_logo_small_6.png",
        "NSJ_logo_small_7.png",
        "NSJ_logo_small_8.png",
        "NSJ_logo_small_9.png"
      ],
      "xl": [
        "NSJ_logo_xl_1.png",
        "NSJ_logo_xl_10.png",
        "NSJ_logo_xl_11.png",
        "NSJ_logo_xl_12.png",
        "NSJ_logo_xl_13.png",
        "NSJ_logo_xl_14.png",
        "NSJ_logo_xl_15.png",
        "NSJ_logo_xl_16.png",
        "NSJ_logo_xl_17.png",
        "NSJ_logo_xl_2.png",
        "NSJ_logo_xl_3.png",
        "NSJ_logo_xl_4.png",
        "NSJ_logo_xl_5.png",
        "NSJ_logo_xl_6.png",
        "NSJ_logo_xl_7.png",
        "NSJ_logo_xl_8.png",
        "NSJ_logo_xl_9.png"
      ],
      "description": ""
    },
    "P44 strona": {
      "large": [
        "p44_logo_large_1.png",
        "p44_logo_large_10.png",
        "p44_logo_large_11.png",
        "p44_logo_large_12.png",
        "p44_logo_large_13.png",
        "p44_logo_large_2.png",
        "p44_logo_large_3.png",
        "p44_logo_large_4.png",
        "p44_logo_large_5.png",
        "p44_logo_large_6.png",
        "p44_logo_large_7.png",
        "p44_logo_large_8.png",
        "p44_logo_large_9.png"
      ],
      "max": [
        "p44_logo_max_1.png",
        "p44_logo_max_10.png",
        "p44_logo_max_11.png",
        "p44_logo_max_12.png",
        "p44_logo_max_13.png",
        "p44_logo_max_2.png",
        "p44_logo_max_3.png",
        "p44_logo_max_4.png",
        "p44_logo_max_5.png",
        "p44_logo_max_6.png",
        "p44_logo_max_7.png",
        "p44_logo_max_8.png",
        "p44_logo_max_9.png"
      ],
      "medium": [
        "p44_logo_medium_1.png",
        "p44_logo_medium_10.png",
        "p44_logo_medium_11.png",
        "p44_logo_medium_12.png",
        "p44_logo_medium_13.png",
        "p44_logo_medium_2.png",
        "p44_logo_medium_3.png",
        "p44_logo_medium_4.png",
        "p44_logo_medium_5.png",
        "p44_logo_medium_6.png",
        "p44_logo_medium_7.png",
        "p44_logo_medium_8.png",
        "p44_logo_medium_9.png"
      ],
      "mini": [
        "p44_mini_1.png",
        "p44_mini_10.png",
        "p44_mini_11.png",
        "p44_mini_12.png",
        "p44_mini_13.png",
        "p44_mini_2.png",
        "p44_mini_3.png",
        "p44_mini_4.png",
        "p44_mini_5.png",
        "p44_mini_6.png",
        "p44_mini_7.png",
        "p44_mini_8.png",
        "p44_mini_9.png"
      ],
      "small": [
        "p44_logo_small_1.png",
        "p44_logo_small_10.png",
        "p44_logo_small_11.png",
        "p44_logo_small_12.png",
        "p44_logo_small_13.png",
        "p44_logo_small_2.png",
        "p44_logo_small_3.png",
        "p44_logo_small_4.png",
        "p44_logo_small_5.png",
        "p44_logo_small_6.png",
        "p44_logo_small_7.png",
        "p44_logo_small_8.png",
        "p44_logo_small_9.png"
      ],
      "xl": [
        "p44_logo_xl_1.png",
        "p44_logo_xl_10.png",
        "p44_logo_xl_11.png",
        "p44_logo_xl_12.png",
        "p44_logo_xl_13.png",
        "p44_logo_xl_2.png",
        "p44_logo_xl_3.png",
        "p44_logo_xl_4.png",
        "p44_logo_xl_5.png",
        "p44_logo_xl_6.png",
        "p44_logo_xl_7.png",
        "p44_logo_xl_8.png",
        "p44_logo_xl_9.png"
      ],
      "description": ""
    },
    "Smay": {
      "large": [
        "Smay2_logo_large_1.png",
        "Smay2_logo_large_2.png",
        "Smay2_logo_large_3.png",
        "Smay2_logo_large_4.png",
        "Smay2_logo_large_5.png",
        "Smay2_logo_large_6.png",
        "Smay_logo_large_1.png",
        "Smay_logo_large_10.png",
        "Smay_logo_large_12.png",
        "Smay_logo_large_13.png",
        "Smay_logo_large_14.png",
        "Smay_logo_large_15.png",
        "Smay_logo_large_16.png",
        "Smay_logo_large_17.png",
        "Smay_logo_large_3.png",
        "Smay_logo_large_4.png",
        "Smay_logo_large_5.png",
        "Smay_logo_large_6.png",
        "Smay_logo_large_7.png",
        "Smay_logo_large_8.png",
        "Smay_logo_large_9.png"
      ],
      "max": [
        "SMAY.png",
        "Smay2_logo_max_1.png",
        "Smay2_logo_max_2.png",
        "Smay2_logo_max_3.png",
        "Smay2_logo_max_4.png",
        "Smay2_logo_max_5.png",
        "Smay2_logo_max_6.png",
        "Smay_logo_max_1.png",
        "Smay_logo_max_10.png",
        "Smay_logo_max_12.png",
        "Smay_logo_max_13.png",
        "Smay_logo_max_14.png",
        "Smay_logo_max_15.png",
        "Smay_logo_max_17.png",
        "Smay_logo_max_3.png",
        "Smay_logo_max_4.png",
        "Smay_logo_max_5.png",
        "Smay_logo_max_6.png",
        "Smay_logo_max_7.png",
        "Smay_logo_max_8.png",
        "Smay_logo_max_9.png"
      ],
      "medium": [
        "Smay2_logo_medium_1.png",
        "Smay2_logo_medium_2.png",
        "Smay2_logo_medium_3.png",
        "Smay2_logo_medium_4.png",
        "Smay2_logo_medium_5.png",
        "Smay2_logo_medium_6.png",
        "Smay_logo_medium_1.png",
        "Smay_logo_medium_10.png",
        "Smay_logo_medium_12.png",
        "Smay_logo_medium_13.png",
        "Smay_logo_medium_14.png",
        "Smay_logo_medium_15.png",
        "Smay_logo_medium_16.png",
        "Smay_logo_medium_17.png",
        "Smay_logo_medium_3.png",
        "Smay_logo_medium_4.png",
        "Smay_logo_medium_5.png",
        "Smay_logo_medium_6.png",
        "Smay_logo_medium_7.png",
        "Smay_logo_medium_8.png",
        "Smay_logo_medium_9.png"
      ],
      "mini": [
        "Smay2_mini_1.png",
        "Smay2_mini_2.png",
        "Smay2_mini_3.png",
        "Smay2_mini_4.png",
        "Smay2_mini_5.png",
        "Smay2_mini_6.png",
        "Smay_mini_1.png",
        "Smay_mini_10.png",
        "Smay_mini_12.png",
        "Smay_mini_13.png",
        "Smay_mini_14.png",
        "Smay_mini_15.png",
        "Smay_mini_16.png",
        "Smay_mini_17.png",
        "Smay_mini_3.png",
        "Smay_mini_4.png",
        "Smay_mini_5.png",
        "Smay_mini_6.png",
        "Smay_mini_7.png",
        "Smay_mini_8.png",
        "Smay_mini_9.png"
      ],
      "small": [
        "Smay2_logo_small_1.png",
        "Smay2_logo_small_2.png",
        "Smay2_logo_small_3.png",
        "Smay2_logo_small_4.png",
        "Smay2_logo_small_5.png",
        "Smay2_logo_small_6.png",
        "Smay_logo_small_1.png",
        "Smay_logo_small_10.png",
        "Smay_logo_small_12.png",
        "Smay_logo_small_13.png",
        "Smay_logo_small_14.png",
        "Smay_logo_small_15.png",
        "Smay_logo_small_16.png",
        "Smay_logo_small_17.png",
        "Smay_logo_small_3.png",
        "Smay_logo_small_4.png",
        "Smay_logo_small_5.png",
        "Smay_logo_small_6.png",
        "Smay_logo_small_7.png",
        "Smay_logo_small_8.png",
        "Smay_logo_small_9.png"
      ],
      "xl": [
        "Smay2_logo_xl_1.png",
        "Smay2_logo_xl_2.png",
        "Smay2_logo_xl_3.png",
        "Smay2_logo_xl_4.png",
        "Smay2_logo_xl_5.png",
        "Smay2_logo_xl_6.png",
        "Smay_logo_xl_1.png",
        "Smay_logo_xl_10.png",
        "Smay_logo_xl_12.png",
        "Smay_logo_xl_13.png",
        "Smay_logo_xl_14.png",
        "Smay_logo_xl_15.png",
        "Smay_logo_xl_16.png",
        "Smay_logo_xl_17.png",
        "Smay_logo_xl_3.png",
        "Smay_logo_xl_4.png",
        "Smay_logo_xl_5.png",
        "Smay_logo_xl_6.png",
        "Smay_logo_xl_7.png",
        "Smay_logo_xl_8.png",
        "Smay_logo_xl_9.png"
      ],
      "description": ""
    }
  },
  "restauracje": {
    "Drugie Dno Nowogrodzka - Gastropub Warszawa": {
      "large": [
        "DrugieDno_large_logo_1.png",
        "DrugieDno_large_logo_10.png",
        "DrugieDno_large_logo_2.png",
        "DrugieDno_large_logo_3.png",
        "DrugieDno_large_logo_4.png",
        "DrugieDno_large_logo_5.png",
        "DrugieDno_large_logo_6.png",
        "DrugieDno_large_logo_7.png",
        "DrugieDno_large_logo_8.png",
        "DrugieDno_large_logo_9.png"
      ],
      "max": [
        "DrugieDno_max_logo_1.png",
        "DrugieDno_max_logo_10.png",
        "DrugieDno_max_logo_2.png",
        "DrugieDno_max_logo_3.png",
        "DrugieDno_max_logo_4.png",
        "DrugieDno_max_logo_5.png",
        "DrugieDno_max_logo_6.png",
        "DrugieDno_max_logo_7.png",
        "DrugieDno_max_logo_8.png",
        "DrugieDno_max_logo_9.png"
      ],
      "medium": [
        "DrugieDno_medium_logo_1.png",
        "DrugieDno_medium_logo_10.png",
        "DrugieDno_medium_logo_2.png",
        "DrugieDno_medium_logo_3.png",
        "DrugieDno_medium_logo_4.png",
        "DrugieDno_medium_logo_5.png",
        "DrugieDno_medium_logo_6.png",
        "DrugieDno_medium_logo_7.png",
        "DrugieDno_medium_logo_8.png",
        "DrugieDno_medium_logo_9.png"
      ],
      "mini": [
        "DrugieDno_mini_1.png",
        "DrugieDno_mini_10.png",
        "DrugieDno_mini_2.png",
        "DrugieDno_mini_3.png",
        "DrugieDno_mini_4.png",
        "DrugieDno_mini_5.png",
        "DrugieDno_mini_6.png",
        "DrugieDno_mini_7.png",
        "DrugieDno_mini_8.png",
        "DrugieDno_mini_9.png"
      ],
      "small": [
        "DrugieDno_small_logo_1.png",
        "DrugieDno_small_logo_10.png",
        "DrugieDno_small_logo_2.png",
        "DrugieDno_small_logo_3.png",
        "DrugieDno_small_logo_4.png",
        "DrugieDno_small_logo_5.png",
        "DrugieDno_small_logo_6.png",
        "DrugieDno_small_logo_7.png",
        "DrugieDno_small_logo_8.png",
        "DrugieDno_small_logo_9.png"
      ],
      "xl": [
        "DrugieDno_xl_logo_1.png",
        "DrugieDno_xl_logo_10.png",
        "DrugieDno_xl_logo_2.png",
        "DrugieDno_xl_logo_3.png",
        "DrugieDno_xl_logo_4.png",
        "DrugieDno_xl_logo_5.png",
        "DrugieDno_xl_logo_6.png",
        "DrugieDno_xl_logo_7.png",
        "DrugieDno_xl_logo_8.png",
        "DrugieDno_xl_logo_9.png"
      ],
      "description": "Zlecenie wystroju restauracji zawsze wiąże się z wielkim wyzwaniem. Wyzwaniem jest sam projekt, który musi pasować do charakteru samej restauracji oraz koordynacja, aby wszystkie prace przygotowawcze oraz montażowe były przeprowadzone w terminie. W tej realizacji jednym z wyzwań była komora chłodnicza naszego autorstwa, która musiała posiadać odpowiednią powierzchnię magazynową dla beczek oraz butelek Cratowego piwa i jednocześnie uwzględniając jej usytuowanie w przestrzeni konsumpcyjnej, kształtować charakter miejsca z naciskiem na różnorodność serwowanego w lokalu piwa Craftowego. Aby zachować oryginalny i estetyczny wygląd zaproponowaliśmy, aby cała komora z zewnątrz została przeszklona, zaspokajając jednocześnie ciekawość miłośników piwa w zakresie sposobu jego właściwego przechowywania. W ścianie komory zostały umieszczone nalewaki dające klientowi w określonych okolicznościach zorganizowanego spotkania, możliwość samodzielnego nalania 3 różnych gatunków piwa. Kolejnym wyzwaniem było stworzenie kuchni, pasującej do charakteru kamienicy, zachowując przy tym funkcjonalność oraz spełniając wszelkie wymogi sanitarne. Udało się zachować oryginalne elewacje ścian, przy wykorzystaniu szyby hartowanej zapewniając bezpieczeństwo i higienę pracy na kuchni.\nZ wyniku prac jesteśmy nie mniej zadowoleni niż właściciele Multitap baru Drugie Dno zlokalizowanego przy ul. Nowogrodzkiej 4 w centrum Warszawy, w ponad stuletniej kamienicy.\nWynik prac można podziwiać w lokalu, jak i w naszej galerii poniżej.\nSerdecznie zapraszamy !"
    },
    "Dwa Smaki Słomniki": {
      "large": [
        "Dss_logo_large_1.png",
        "Dss_logo_large_10.png",
        "Dss_logo_large_11.png",
        "Dss_logo_large_12.png",
        "Dss_logo_large_13.png",
        "Dss_logo_large_14.png",
        "Dss_logo_large_15.png",
        "Dss_logo_large_16.png",
        "Dss_logo_large_2.png",
        "Dss_logo_large_3.png",
        "Dss_logo_large_4.png",
        "Dss_logo_large_5.png",
        "Dss_logo_large_6.png",
        "Dss_logo_large_7.png",
        "Dss_logo_large_8.png",
        "Dss_logo_large_9.png"
      ],
      "max": [
        "Dss_logo_max_1.png",
        "Dss_logo_max_10.png",
        "Dss_logo_max_11.png",
        "Dss_logo_max_12.png",
        "Dss_logo_max_13.png",
        "Dss_logo_max_14.png",
        "Dss_logo_max_15.png",
        "Dss_logo_max_16.png",
        "Dss_logo_max_2.png",
        "Dss_logo_max_3.png",
        "Dss_logo_max_4.png",
        "Dss_logo_max_5.png",
        "Dss_logo_max_6.png",
        "Dss_logo_max_7.png",
        "Dss_logo_max_8.png",
        "Dss_logo_max_9.png"
      ],
      "medium": [
        "Dss_logo_medium_1.png",
        "Dss_logo_medium_10.png",
        "Dss_logo_medium_11.png",
        "Dss_logo_medium_12.png",
        "Dss_logo_medium_13.png",
        "Dss_logo_medium_14.png",
        "Dss_logo_medium_15.png",
        "Dss_logo_medium_16.png",
        "Dss_logo_medium_2.png",
        "Dss_logo_medium_3.png",
        "Dss_logo_medium_4.png",
        "Dss_logo_medium_5.png",
        "Dss_logo_medium_6.png",
        "Dss_logo_medium_7.png",
        "Dss_logo_medium_8.png",
        "Dss_logo_medium_9.png"
      ],
      "mini": [
        "Dss_mini_1.png",
        "Dss_mini_10.png",
        "Dss_mini_11.png",
        "Dss_mini_12.png",
        "Dss_mini_13.png",
        "Dss_mini_14.png",
        "Dss_mini_15.png",
        "Dss_mini_16.png",
        "Dss_mini_2.png",
        "Dss_mini_3.png",
        "Dss_mini_4.png",
        "Dss_mini_5.png",
        "Dss_mini_6.png",
        "Dss_mini_7.png",
        "Dss_mini_8.png",
        "Dss_mini_9.png"
      ],
      "small": [
        "Dss_logo_small_1.png",
        "Dss_logo_small_10.png",
        "Dss_logo_small_11.png",
        "Dss_logo_small_12.png",
        "Dss_logo_small_13.png",
        "Dss_logo_small_14.png",
        "Dss_logo_small_15.png",
        "Dss_logo_small_16.png",
        "Dss_logo_small_2.png",
        "Dss_logo_small_3.png",
        "Dss_logo_small_4.png",
        "Dss_logo_small_5.png",
        "Dss_logo_small_6.png",
        "Dss_logo_small_7.png",
        "Dss_logo_small_8.png",
        "Dss_logo_small_9.png"
      ],
      "xl": [
        "Dss_logo_xl_1.png",
        "Dss_logo_xl_10.png",
        "Dss_logo_xl_11.png",
        "Dss_logo_xl_12.png",
        "Dss_logo_xl_13.png",
        "Dss_logo_xl_14.png",
        "Dss_logo_xl_15.png",
        "Dss_logo_xl_16.png",
        "Dss_logo_xl_2.png",
        "Dss_logo_xl_3.png",
        "Dss_logo_xl_4.png",
        "Dss_logo_xl_5.png",
        "Dss_logo_xl_6.png",
        "Dss_logo_xl_7.png",
        "Dss_logo_xl_8.png",
        "Dss_logo_xl_9.png"
      ],
      "description": ""
    },
    "Pizzeria Riccardo Opatkowice": {
      "large": [
        "Ro_logo_large_1.png",
        "Ro_logo_large_2.png",
        "Ro_logo_large_3.png",
        "Ro_logo_large_4.png",
        "Ro_logo_large_5.png",
        "Ro_logo_large_6.png",
        "Ro_logo_large_7.png"
      ],
      "max": [
        "Ro_logo_max_1.png",
        "Ro_logo_max_2.png",
        "Ro_logo_max_3.png",
        "Ro_logo_max_4.png",
        "Ro_logo_max_5.png",
        "Ro_logo_max_6.png",
        "Ro_logo_max_7.png"
      ],
      "medium": [
        "Ro_logo_medium_1.png",
        "Ro_logo_medium_2.png",
        "Ro_logo_medium_3.png",
        "Ro_logo_medium_4.png",
        "Ro_logo_medium_5.png",
        "Ro_logo_medium_6.png",
        "Ro_logo_medium_7.png"
      ],
      "mini": [
        "Ro_mini_1.png",
        "Ro_mini_2.png",
        "Ro_mini_3.png",
        "Ro_mini_4.png",
        "Ro_mini_5.png",
        "Ro_mini_6.png",
        "Ro_mini_7.png"
      ],
      "small": [
        "Ro_logo_small_1.png",
        "Ro_logo_small_2.png",
        "Ro_logo_small_3.png",
        "Ro_logo_small_4.png",
        "Ro_logo_small_5.png",
        "Ro_logo_small_6.png",
        "Ro_logo_small_7.png"
      ],
      "xl": [
        "Ro_logo_xl_1.png",
        "Ro_logo_xl_2.png",
        "Ro_logo_xl_3.png",
        "Ro_logo_xl_4.png",
        "Ro_logo_xl_5.png",
        "Ro_logo_xl_6.png",
        "Ro_logo_xl_7.png"
      ],
      "description": ""
    }
  },
  "sklepy": {
    "Euro Sklep Osieczany": {
      "large": [
        "ESO_logo_large_1.png",
        "ESO_logo_large_10.png",
        "ESO_logo_large_11.png",
        "ESO_logo_large_12.png",
        "ESO_logo_large_2.png",
        "ESO_logo_large_3.png",
        "ESO_logo_large_4.png",
        "ESO_logo_large_5.png",
        "ESO_logo_large_6.png",
        "ESO_logo_large_7.png",
        "ESO_logo_large_8.png",
        "ESO_logo_large_9.png"
      ],
      "max": [
        "ESO_logo_max_1.png",
        "ESO_logo_max_10.png",
        "ESO_logo_max_11.png",
        "ESO_logo_max_12.png",
        "ESO_logo_max_2.png",
        "ESO_logo_max_3.png",
        "ESO_logo_max_4.png",
        "ESO_logo_max_5.png",
        "ESO_logo_max_6.png",
        "ESO_logo_max_7.png",
        "ESO_logo_max_8.png",
        "ESO_logo_max_9.png"
      ],
      "medium": [
        "ESO_logo_medium_1.png",
        "ESO_logo_medium_10.png",
        "ESO_logo_medium_11.png",
        "ESO_logo_medium_12.png",
        "ESO_logo_medium_2.png",
        "ESO_logo_medium_3.png",
        "ESO_logo_medium_4.png",
        "ESO_logo_medium_5.png",
        "ESO_logo_medium_6.png",
        "ESO_logo_medium_7.png",
        "ESO_logo_medium_8.png",
        "ESO_logo_medium_9.png"
      ],
      "mini": [
        "ESO_mini_1.png",
        "ESO_mini_10.png",
        "ESO_mini_11.png",
        "ESO_mini_12.png",
        "ESO_mini_2.png",
        "ESO_mini_3.png",
        "ESO_mini_4.png",
        "ESO_mini_5.png",
        "ESO_mini_6.png",
        "ESO_mini_7.png",
        "ESO_mini_8.png",
        "ESO_mini_9.png"
      ],
      "small": [
        "ESO_logo_small_1.png",
        "ESO_logo_small_10.png",
        "ESO_logo_small_11.png",
        "ESO_logo_small_12.png",
        "ESO_logo_small_2.png",
        "ESO_logo_small_3.png",
        "ESO_logo_small_4.png",
        "ESO_logo_small_5.png",
        "ESO_logo_small_6.png",
        "ESO_logo_small_7.png",
        "ESO_logo_small_8.png",
        "ESO_logo_small_9.png"
      ],
      "xl": [
        "ESO_logo_xl_1.png",
        "ESO_logo_xl_10.png",
        "ESO_logo_xl_11.png",
        "ESO_logo_xl_12.png",
        "ESO_logo_xl_2.png",
        "ESO_logo_xl_3.png",
        "ESO_logo_xl_4.png",
        "ESO_logo_xl_5.png",
        "ESO_logo_xl_6.png",
        "ESO_logo_xl_7.png",
        "ESO_logo_xl_8.png",
        "ESO_logo_xl_9.png"
      ],
      "description": ""
    },
    "Euro Sklep Sami Swoi Glinik": {
      "large": [
        "glinik_large_logo_1.png",
        "glinik_large_logo_2.png",
        "glinik_large_logo_3.png",
        "glinik_large_logo_4.png",
        "glinik_large_logo_5.png",
        "glinik_large_logo_6.png",
        "glinik_large_logo_7.png",
        "glinik_large_logo_8.png",
        "glinik_large_logo_9.png"
      ],
      "max": [
        "glinik_max_logo_1.png",
        "glinik_max_logo_2.png",
        "glinik_max_logo_3.png",
        "glinik_max_logo_4.png",
        "glinik_max_logo_5.png",
        "glinik_max_logo_6.png",
        "glinik_max_logo_7.png",
        "glinik_max_logo_8.png",
        "glinik_max_logo_9.png"
      ],
      "medium": [
        "glinik_medium_logo_1.png",
        "glinik_medium_logo_2.png",
        "glinik_medium_logo_3.png",
        "glinik_medium_logo_4.png",
        "glinik_medium_logo_5.png",
        "glinik_medium_logo_6.png",
        "glinik_medium_logo_7.png",
        "glinik_medium_logo_8.png",
        "glinik_medium_logo_9.png"
      ],
      "mini": [
        "glinik_mini_1.png",
        "glinik_mini_2.png",
        "glinik_mini_3.png",
        "glinik_mini_4.png",
        "glinik_mini_5.png",
        "glinik_mini_6.png",
        "glinik_mini_7.png",
        "glinik_mini_8.png",
        "glinik_mini_9.png"
      ],
      "small": [
        "glinik_small_logo_1.png",
        "glinik_small_logo_2.png",
        "glinik_small_logo_3.png",
        "glinik_small_logo_4.png",
        "glinik_small_logo_5.png",
        "glinik_small_logo_6.png",
        "glinik_small_logo_7.png",
        "glinik_small_logo_8.png",
        "glinik_small_logo_9.png"
      ],
      "xl": [
        "glinik_xl_logo_1.png",
        "glinik_xl_logo_2.png",
        "glinik_xl_logo_3.png",
        "glinik_xl_logo_4.png",
        "glinik_xl_logo_5.png",
        "glinik_xl_logo_6.png",
        "glinik_xl_logo_7.png",
        "glinik_xl_logo_8.png",
        "glinik_xl_logo_9.png"
      ],
      "description": ""
    },
    "Euro Sklep Sami Swoi Igołomia": {
      "large": [
        "IG_logo_large_1.png",
        "IG_logo_large_2.png",
        "IG_logo_large_3.png",
        "IG_logo_large_4.png",
        "IG_logo_large_5.png",
        "IG_logo_large_6.png",
        "IG_logo_large_7.png"
      ],
      "max": [
        "IG_logo_max_1.png",
        "IG_logo_max_2.png",
        "IG_logo_max_3.png",
        "IG_logo_max_4.png",
        "IG_logo_max_5.png",
        "IG_logo_max_6.png",
        "IG_logo_max_7.png"
      ],
      "medium": [
        "IG_logo_medium_1.png",
        "IG_logo_medium_2.png",
        "IG_logo_medium_3.png",
        "IG_logo_medium_4.png",
        "IG_logo_medium_5.png",
        "IG_logo_medium_6.png",
        "IG_logo_medium_7.png"
      ],
      "mini": [
        "IG_mini_1.png",
        "IG_mini_2.png",
        "IG_mini_3.png",
        "IG_mini_4.png",
        "IG_mini_5.png",
        "IG_mini_7.png"
      ],
      "small": [
        "IG_logo_small_1.png",
        "IG_logo_small_2.png",
        "IG_logo_small_3.png",
        "IG_logo_small_4.png",
        "IG_logo_small_5.png",
        "IG_logo_small_6.png",
        "IG_logo_small_7.png"
      ],
      "xl": [
        "IG_logo_xl_1.png",
        "IG_logo_xl_2.png",
        "IG_logo_xl_3.png",
        "IG_logo_xl_4.png",
        "IG_logo_xl_5.png",
        "IG_logo_xl_6.png",
        "IG_logo_xl_7.png"
      ],
      "description": ""
    },
    "Euro Sklep Sami Swoi Luborzyca": {
      "large": [
        "LU_logo_large_1.png",
        "LU_logo_large_2.png",
        "LU_logo_large_3.png",
        "LU_logo_large_4.png",
        "LU_logo_large_5.png",
        "LU_logo_large_6.png",
        "LU_logo_large_7.png",
        "LU_logo_large_8.png"
      ],
      "max": [
        "LU_logo_max_1.png",
        "LU_logo_max_2.png",
        "LU_logo_max_3.png",
        "LU_logo_max_4.png",
        "LU_logo_max_5.png",
        "LU_logo_max_6.png",
        "LU_logo_max_7.png",
        "LU_logo_max_8.png"
      ],
      "medium": [
        "LU_logo_medium_1.png",
        "LU_logo_medium_2.png",
        "LU_logo_medium_3.png",
        "LU_logo_medium_4.png",
        "LU_logo_medium_5.png",
        "LU_logo_medium_6.png",
        "LU_logo_medium_7.png",
        "LU_logo_medium_8.png"
      ],
      "mini": [
        "LU_mini_1.png",
        "LU_mini_2.png",
        "LU_mini_3.png",
        "LU_mini_4.png",
        "LU_mini_5.png",
        "LU_mini_6.png",
        "LU_mini_7.png",
        "LU_mini_8.png"
      ],
      "small": [
        "LU_logo_small_1.png",
        "LU_logo_small_2.png",
        "LU_logo_small_3.png",
        "LU_logo_small_4.png",
        "LU_logo_small_5.png",
        "LU_logo_small_6.png",
        "LU_logo_small_7.png",
        "LU_logo_small_8.png"
      ],
      "xl": [
        "LU_logo_xl_1.png",
        "LU_logo_xl_2.png",
        "LU_logo_xl_3.png",
        "LU_logo_xl_4.png",
        "LU_logo_xl_5.png",
        "LU_logo_xl_6.png",
        "LU_logo_xl_7.png",
        "LU_logo_xl_8.png"
      ],
      "description": ""
    },
    "Euro Sklep Sami Swoi Marszowice": {
      "large": [
        "MA_logo_large_1.png",
        "MA_logo_large_10.png",
        "MA_logo_large_11.png",
        "MA_logo_large_12.png",
        "MA_logo_large_2.png",
        "MA_logo_large_3.png",
        "MA_logo_large_4.png",
        "MA_logo_large_5.png",
        "MA_logo_large_6.png",
        "MA_logo_large_7.png",
        "MA_logo_large_8.png",
        "MA_logo_large_9.png"
      ],
      "max": [
        "MA_logo_max_1.png",
        "MA_logo_max_10.png",
        "MA_logo_max_11.png",
        "MA_logo_max_12.png",
        "MA_logo_max_2.png",
        "MA_logo_max_3.png",
        "MA_logo_max_4.png",
        "MA_logo_max_5.png",
        "MA_logo_max_6.png",
        "MA_logo_max_7.png",
        "MA_logo_max_8.png",
        "MA_logo_max_9.png"
      ],
      "medium": [
        "MA_logo_medium_1.png",
        "MA_logo_medium_10.png",
        "MA_logo_medium_11.png",
        "MA_logo_medium_12.png",
        "MA_logo_medium_2.png",
        "MA_logo_medium_3.png",
        "MA_logo_medium_4.png",
        "MA_logo_medium_5.png",
        "MA_logo_medium_6.png",
        "MA_logo_medium_7.png",
        "MA_logo_medium_8.png",
        "MA_logo_medium_9.png"
      ],
      "mini": [
        "MA_mini_1.png",
        "MA_mini_10.png",
        "MA_mini_11.png",
        "MA_mini_12.png",
        "MA_mini_2.png",
        "MA_mini_3.png",
        "MA_mini_4.png",
        "MA_mini_5.png",
        "MA_mini_6.png",
        "MA_mini_7.png",
        "MA_mini_8.png",
        "MA_mini_9.png"
      ],
      "small": [
        "MA_logo_small_1.png",
        "MA_logo_small_10.png",
        "MA_logo_small_11.png",
        "MA_logo_small_12.png",
        "MA_logo_small_2.png",
        "MA_logo_small_3.png",
        "MA_logo_small_4.png",
        "MA_logo_small_5.png",
        "MA_logo_small_6.png",
        "MA_logo_small_7.png",
        "MA_logo_small_8.png",
        "MA_logo_small_9.png"
      ],
      "xl": [
        "MA_logo_xl_1.png",
        "MA_logo_xl_10.png",
        "MA_logo_xl_11.png",
        "MA_logo_xl_12.png",
        "MA_logo_xl_2.png",
        "MA_logo_xl_3.png",
        "MA_logo_xl_4.png",
        "MA_logo_xl_5.png",
        "MA_logo_xl_6.png",
        "MA_logo_xl_7.png",
        "MA_logo_xl_8.png",
        "MA_logo_xl_9.png"
      ],
      "description": ""
    },
    "Euro Sklep Sami Swoi Slomniki": {
      "large": [
        "SL_logo_large_1.png",
        "SL_logo_large_10.png",
        "SL_logo_large_11.png",
        "SL_logo_large_2.png",
        "SL_logo_large_3.png",
        "SL_logo_large_4.png",
        "SL_logo_large_5.png",
        "SL_logo_large_7.png",
        "SL_logo_large_8.png",
        "SL_logo_large_9.png"
      ],
      "max": [
        "SL_logo_max_1.png",
        "SL_logo_max_10.png",
        "SL_logo_max_11.png",
        "SL_logo_max_2.png",
        "SL_logo_max_3.png",
        "SL_logo_max_4.png",
        "SL_logo_max_5.png",
        "SL_logo_max_7.png",
        "SL_logo_max_8.png",
        "SL_logo_max_9.png"
      ],
      "medium": [
        "SL_logo_medium_1.png",
        "SL_logo_medium_10.png",
        "SL_logo_medium_11.png",
        "SL_logo_medium_2.png",
        "SL_logo_medium_3.png",
        "SL_logo_medium_4.png",
        "SL_logo_medium_5.png",
        "SL_logo_medium_7.png",
        "SL_logo_medium_8.png",
        "SL_logo_medium_9.png"
      ],
      "mini": [
        "SL_mini_1.png",
        "SL_mini_10.png",
        "SL_mini_11.png",
        "SL_mini_2.png",
        "SL_mini_3.png",
        "SL_mini_4.png",
        "SL_mini_5.png",
        "SL_mini_7.png",
        "SL_mini_8.png",
        "SL_mini_9.png"
      ],
      "small": [
        "SL_logo_small_1.png",
        "SL_logo_small_10.png",
        "SL_logo_small_11.png",
        "SL_logo_small_2.png",
        "SL_logo_small_3.png",
        "SL_logo_small_4.png",
        "SL_logo_small_5.png",
        "SL_logo_small_7.png",
        "SL_logo_small_8.png",
        "SL_logo_small_9.png"
      ],
      "xl": [
        "SL_logo_xl_1.png",
        "SL_logo_xl_10.png",
        "SL_logo_xl_11.png",
        "SL_logo_xl_2.png",
        "SL_logo_xl_3.png",
        "SL_logo_xl_4.png",
        "SL_logo_xl_5.png",
        "SL_logo_xl_7.png",
        "SL_logo_xl_8.png",
        "SL_logo_xl_9.png"
      ],
      "description": ""
    },
    "Euro Sklep Sami Swoi Wawrzeńczyce": {
      "large": [
        "WA_logo_large_1.png",
        "WA_logo_large_10.png",
        "WA_logo_large_11.png",
        "WA_logo_large_12.png",
        "WA_logo_large_13.png",
        "WA_logo_large_14.png",
        "WA_logo_large_15.png",
        "WA_logo_large_2.png",
        "WA_logo_large_3.png",
        "WA_logo_large_4.png",
        "WA_logo_large_5.png",
        "WA_logo_large_6.png",
        "WA_logo_large_7.png",
        "WA_logo_large_8.png",
        "WA_logo_large_9.png"
      ],
      "max": [
        "WA_logo_max_1.png",
        "WA_logo_max_10.png",
        "WA_logo_max_11.png",
        "WA_logo_max_12.png",
        "WA_logo_max_13.png",
        "WA_logo_max_14.png",
        "WA_logo_max_15.png",
        "WA_logo_max_2.png",
        "WA_logo_max_3.png",
        "WA_logo_max_4.png",
        "WA_logo_max_5.png",
        "WA_logo_max_6.png",
        "WA_logo_max_7.png",
        "WA_logo_max_8.png",
        "WA_logo_max_9.png"
      ],
      "medium": [
        "WA_logo_medium_1.png",
        "WA_logo_medium_10.png",
        "WA_logo_medium_11.png",
        "WA_logo_medium_12.png",
        "WA_logo_medium_13.png",
        "WA_logo_medium_14.png",
        "WA_logo_medium_15.png",
        "WA_logo_medium_2.png",
        "WA_logo_medium_3.png",
        "WA_logo_medium_4.png",
        "WA_logo_medium_5.png",
        "WA_logo_medium_6.png",
        "WA_logo_medium_7.png",
        "WA_logo_medium_8.png",
        "WA_logo_medium_9.png"
      ],
      "mini": [
        "WA_mini_1.png",
        "WA_mini_10.png",
        "WA_mini_11.png",
        "WA_mini_12.png",
        "WA_mini_13.png",
        "WA_mini_14.png",
        "WA_mini_15.png",
        "WA_mini_2.png",
        "WA_mini_3.png",
        "WA_mini_4.png",
        "WA_mini_5.png",
        "WA_mini_6.png",
        "WA_mini_7.png",
        "WA_mini_8.png",
        "WA_mini_9.png"
      ],
      "small": [
        "WA_logo_small_1.png",
        "WA_logo_small_10.png",
        "WA_logo_small_11.png",
        "WA_logo_small_12.png",
        "WA_logo_small_13.png",
        "WA_logo_small_14.png",
        "WA_logo_small_15.png",
        "WA_logo_small_2.png",
        "WA_logo_small_3.png",
        "WA_logo_small_4.png",
        "WA_logo_small_5.png",
        "WA_logo_small_6.png",
        "WA_logo_small_7.png",
        "WA_logo_small_8.png",
        "WA_logo_small_9.png"
      ],
      "xl": [
        "WA_logo_xl_1.png",
        "WA_logo_xl_10.png",
        "WA_logo_xl_11.png",
        "WA_logo_xl_12.png",
        "WA_logo_xl_13.png",
        "WA_logo_xl_14.png",
        "WA_logo_xl_15.png",
        "WA_logo_xl_2.png",
        "WA_logo_xl_3.png",
        "WA_logo_xl_4.png",
        "WA_logo_xl_5.png",
        "WA_logo_xl_6.png",
        "WA_logo_xl_7.png",
        "WA_logo_xl_8.png",
        "WA_logo_xl_9.png"
      ],
      "description": ""
    },
    "Euro Sklep Sami Swoi Wola Filipowska": {
      "large": [
        "Wola_logo_large_1.png",
        "Wola_logo_large_10.png",
        "Wola_logo_large_11.png",
        "Wola_logo_large_12.png",
        "Wola_logo_large_13.png",
        "Wola_logo_large_14.png",
        "Wola_logo_large_15.png",
        "Wola_logo_large_16.png",
        "Wola_logo_large_17.png",
        "Wola_logo_large_2.png",
        "Wola_logo_large_3.png",
        "Wola_logo_large_4.png",
        "Wola_logo_large_5.png",
        "Wola_logo_large_6.png",
        "Wola_logo_large_7.png",
        "Wola_logo_large_8.png",
        "Wola_logo_large_9.png"
      ],
      "max": [
        "Wola_logo_max_1.png",
        "Wola_logo_max_10.png",
        "Wola_logo_max_11.png",
        "Wola_logo_max_12.png",
        "Wola_logo_max_13.png",
        "Wola_logo_max_14.png",
        "Wola_logo_max_15.png",
        "Wola_logo_max_16.png",
        "Wola_logo_max_17.png",
        "Wola_logo_max_2.png",
        "Wola_logo_max_3.png",
        "Wola_logo_max_4.png",
        "Wola_logo_max_5.png",
        "Wola_logo_max_6.png",
        "Wola_logo_max_7.png",
        "Wola_logo_max_8.png",
        "Wola_logo_max_9.png"
      ],
      "medium": [
        "Wola_logo_medium_1.png",
        "Wola_logo_medium_10.png",
        "Wola_logo_medium_11.png",
        "Wola_logo_medium_12.png",
        "Wola_logo_medium_13.png",
        "Wola_logo_medium_14.png",
        "Wola_logo_medium_15.png",
        "Wola_logo_medium_16.png",
        "Wola_logo_medium_17.png",
        "Wola_logo_medium_2.png",
        "Wola_logo_medium_3.png",
        "Wola_logo_medium_4.png",
        "Wola_logo_medium_5.png",
        "Wola_logo_medium_6.png",
        "Wola_logo_medium_7.png",
        "Wola_logo_medium_8.png",
        "Wola_logo_medium_9.png"
      ],
      "mini": [],
      "small": [
        "Wola_logo_small_1.png",
        "Wola_logo_small_10.png",
        "Wola_logo_small_11.png",
        "Wola_logo_small_12.png",
        "Wola_logo_small_13.png",
        "Wola_logo_small_14.png",
        "Wola_logo_small_15.png",
        "Wola_logo_small_16.png",
        "Wola_logo_small_17.png",
        "Wola_logo_small_2.png",
        "Wola_logo_small_3.png",
        "Wola_logo_small_4.png",
        "Wola_logo_small_5.png",
        "Wola_logo_small_6.png",
        "Wola_logo_small_7.png",
        "Wola_logo_small_8.png",
        "Wola_logo_small_9.png"
      ],
      "xl": [
        "Wola_logo_xl_1.png",
        "Wola_logo_xl_10.png",
        "Wola_logo_xl_11.png",
        "Wola_logo_xl_12.png",
        "Wola_logo_xl_13.png",
        "Wola_logo_xl_14.png",
        "Wola_logo_xl_15.png",
        "Wola_logo_xl_16.png",
        "Wola_logo_xl_17.png",
        "Wola_logo_xl_2.png",
        "Wola_logo_xl_3.png",
        "Wola_logo_xl_4.png",
        "Wola_logo_xl_5.png",
        "Wola_logo_xl_6.png",
        "Wola_logo_xl_7.png",
        "Wola_logo_xl_8.png",
        "Wola_logo_xl_9.png"
      ],
      "description": ""
    },
    "Groszek Sami Swoi Masłomiąca": {
      "large": [
        "GMA_logo_large_1.png",
        "GMA_logo_large_10.png",
        "GMA_logo_large_11.png",
        "GMA_logo_large_12.png",
        "GMA_logo_large_2.png",
        "GMA_logo_large_3.png",
        "GMA_logo_large_4.png",
        "GMA_logo_large_5.png",
        "GMA_logo_large_6.png",
        "GMA_logo_large_7.png",
        "GMA_logo_large_8.png",
        "GMA_logo_large_9.png"
      ],
      "max": [
        "GMA_logo_max_1.png",
        "GMA_logo_max_10.png",
        "GMA_logo_max_11.png",
        "GMA_logo_max_12.png",
        "GMA_logo_max_2.png",
        "GMA_logo_max_3.png",
        "GMA_logo_max_4.png",
        "GMA_logo_max_5.png",
        "GMA_logo_max_6.png",
        "GMA_logo_max_7.png",
        "GMA_logo_max_8.png",
        "GMA_logo_max_9.png"
      ],
      "medium": [
        "GMA_logo_medium_1.png",
        "GMA_logo_medium_10.png",
        "GMA_logo_medium_11.png",
        "GMA_logo_medium_12.png",
        "GMA_logo_medium_2.png",
        "GMA_logo_medium_3.png",
        "GMA_logo_medium_4.png",
        "GMA_logo_medium_5.png",
        "GMA_logo_medium_6.png",
        "GMA_logo_medium_7.png",
        "GMA_logo_medium_8.png",
        "GMA_logo_medium_9.png"
      ],
      "mini": [
        "GMA_mini_1.png",
        "GMA_mini_10.png",
        "GMA_mini_11.png",
        "GMA_mini_12.png",
        "GMA_mini_2.png",
        "GMA_mini_3.png",
        "GMA_mini_4.png",
        "GMA_mini_5.png",
        "GMA_mini_6.png",
        "GMA_mini_7.png",
        "GMA_mini_8.png",
        "GMA_mini_9.png"
      ],
      "small": [
        "GMA_logo_small_1.png",
        "GMA_logo_small_10.png",
        "GMA_logo_small_11.png",
        "GMA_logo_small_12.png",
        "GMA_logo_small_2.png",
        "GMA_logo_small_3.png",
        "GMA_logo_small_4.png",
        "GMA_logo_small_5.png",
        "GMA_logo_small_6.png",
        "GMA_logo_small_7.png",
        "GMA_logo_small_8.png",
        "GMA_logo_small_9.png"
      ],
      "xl": [
        "GMA_logo_xl_1.png",
        "GMA_logo_xl_10.png",
        "GMA_logo_xl_11.png",
        "GMA_logo_xl_12.png",
        "GMA_logo_xl_2.png",
        "GMA_logo_xl_3.png",
        "GMA_logo_xl_4.png",
        "GMA_logo_xl_5.png",
        "GMA_logo_xl_6.png",
        "GMA_logo_xl_7.png",
        "GMA_logo_xl_8.png",
        "GMA_logo_xl_9.png"
      ],
      "description": ""
    },
    "Kuźnia Smaków Biernat": {
      "large": [
        "Biernat_large_logo_1.png",
        "Biernat_large_logo_2.png",
        "Biernat_large_logo_3.png",
        "Biernat_large_logo_4.png",
        "Biernat_large_logo_5.png",
        "Biernat_large_logo_6.png",
        "Biernat_large_logo_7.png"
      ],
      "max": [
        "Biernat_max_logo_1.png",
        "Biernat_max_logo_2.png",
        "Biernat_max_logo_3.png",
        "Biernat_max_logo_4.png",
        "Biernat_max_logo_5.png",
        "Biernat_max_logo_6.png",
        "Biernat_max_logo_7.png"
      ],
      "medium": [
        "Biernat_medium_logo_1.png",
        "Biernat_medium_logo_2.png",
        "Biernat_medium_logo_3.png",
        "Biernat_medium_logo_4.png",
        "Biernat_medium_logo_5.png",
        "Biernat_medium_logo_6.png",
        "Biernat_medium_logo_7.png"
      ],
      "mini": [
        "Biernat_mini_1.png",
        "Biernat_mini_2.png",
        "Biernat_mini_3.png",
        "Biernat_mini_4.png",
        "Biernat_mini_5.png",
        "Biernat_mini_6.png",
        "Biernat_mini_7.png"
      ],
      "small": [
        "Biernat_small_logo_1.png",
        "Biernat_small_logo_2.png",
        "Biernat_small_logo_3.png",
        "Biernat_small_logo_4.png",
        "Biernat_small_logo_5.png",
        "Biernat_small_logo_6.png",
        "Biernat_small_logo_7.png"
      ],
      "xl": [
        "Biernat_xl_logo_1.png",
        "Biernat_xl_logo_2.png",
        "Biernat_xl_logo_3.png",
        "Biernat_xl_logo_4.png",
        "Biernat_xl_logo_5.png",
        "Biernat_xl_logo_6.png",
        "Biernat_xl_logo_7.png"
      ],
      "description": "Wykonanie wyposażania firmowego sklepu garmażeryjnego wiązało się ze stworzeniem całej koncepcji kolorystycznej, designerskiej dbając o zadowolenie każdego klienta odwiedzającego sklep.\nKolorystyka powinna komponować się z logo, tego się trzymaliśmy.\nW Sklepie dominuje czerń, sufit wykonany jest z pojedynczych formatek, złożony w całość na zakładkę dając przestrzenny efekt.\nLadę kasową wykonaliśmy z formatek w różnych kolorach laminatu.\nSzafki wiszące nad stanowiskiem mięso-wędliny wykonane są z konstrukcji stalowej, drzwi oraz ościeżnica wykonana jest z tej samej płyty meblowej co reszta zabudowy.\nEfekt naszych prac prezentujemy na zdjęciach."
    },
    "Lewiatan Budzów": {
      "large": [
        "LB_logo_large_1.png",
        "LB_logo_large_10.png",
        "LB_logo_large_11.png",
        "LB_logo_large_12.png",
        "LB_logo_large_13.png",
        "LB_logo_large_14.png",
        "LB_logo_large_2.png",
        "LB_logo_large_3.png",
        "LB_logo_large_4.png",
        "LB_logo_large_5.png",
        "LB_logo_large_6.png",
        "LB_logo_large_7.png",
        "LB_logo_large_8.png",
        "LB_logo_large_9.png"
      ],
      "max": [
        "LB_logo_max_1.png",
        "LB_logo_max_10.png",
        "LB_logo_max_11.png",
        "LB_logo_max_12.png",
        "LB_logo_max_13.png",
        "LB_logo_max_14.png",
        "LB_logo_max_2.png",
        "LB_logo_max_3.png",
        "LB_logo_max_4.png",
        "LB_logo_max_5.png",
        "LB_logo_max_6.png",
        "LB_logo_max_7.png",
        "LB_logo_max_8.png",
        "LB_logo_max_9.png"
      ],
      "medium": [
        "LB_logo_medium_1.png",
        "LB_logo_medium_10.png",
        "LB_logo_medium_11.png",
        "LB_logo_medium_12.png",
        "LB_logo_medium_13.png",
        "LB_logo_medium_14.png",
        "LB_logo_medium_2.png",
        "LB_logo_medium_3.png",
        "LB_logo_medium_4.png",
        "LB_logo_medium_5.png",
        "LB_logo_medium_6.png",
        "LB_logo_medium_7.png",
        "LB_logo_medium_8.png",
        "LB_logo_medium_9.png"
      ],
      "mini": [
        "LB_mini_1.png",
        "LB_mini_10.png",
        "LB_mini_11.png",
        "LB_mini_12.png",
        "LB_mini_13.png",
        "LB_mini_14.png",
        "LB_mini_2.png",
        "LB_mini_3.png",
        "LB_mini_4.png",
        "LB_mini_5.png",
        "LB_mini_6.png",
        "LB_mini_7.png",
        "LB_mini_8.png",
        "LB_mini_9.png"
      ],
      "small": [
        "LB_logo_small_1.png",
        "LB_logo_small_10.png",
        "LB_logo_small_11.png",
        "LB_logo_small_12.png",
        "LB_logo_small_13.png",
        "LB_logo_small_14.png",
        "LB_logo_small_2.png",
        "LB_logo_small_3.png",
        "LB_logo_small_4.png",
        "LB_logo_small_5.png",
        "LB_logo_small_6.png",
        "LB_logo_small_7.png",
        "LB_logo_small_8.png",
        "LB_logo_small_9.png"
      ],
      "xl": [
        "LB_logo_xl_1.png",
        "LB_logo_xl_10.png",
        "LB_logo_xl_11.png",
        "LB_logo_xl_12.png",
        "LB_logo_xl_13.png",
        "LB_logo_xl_14.png",
        "LB_logo_xl_2.png",
        "LB_logo_xl_3.png",
        "LB_logo_xl_4.png",
        "LB_logo_xl_5.png",
        "LB_logo_xl_6.png",
        "LB_logo_xl_7.png",
        "LB_logo_xl_8.png",
        "LB_logo_xl_9.png"
      ],
      "description": ""
    },
    "Lewiatan Wadowice": {
      "large": [
        "LW_logo_large_1.png",
        "LW_logo_large_2.png",
        "LW_logo_large_4.png",
        "LW_logo_large_5.png",
        "LW_logo_large_6.png",
        "LW_logo_large_7.png"
      ],
      "max": [
        "LW_logo_max_1.png",
        "LW_logo_max_2.png",
        "LW_logo_max_4.png",
        "LW_logo_max_5.png",
        "LW_logo_max_6.png",
        "LW_logo_max_7.png"
      ],
      "medium": [
        "LW_logo_medium_1.png",
        "LW_logo_medium_2.png",
        "LW_logo_medium_4.png",
        "LW_logo_medium_5.png",
        "LW_logo_medium_6.png",
        "LW_logo_medium_7.png"
      ],
      "mini": [
        "LW_mini_1.png",
        "LW_mini_2.png",
        "LW_mini_4.png",
        "LW_mini_5.png",
        "LW_mini_6.png",
        "LW_mini_7.png"
      ],
      "small": [
        "LW_logo_small_1.png",
        "LW_logo_small_2.png",
        "LW_logo_small_4.png",
        "LW_logo_small_5.png",
        "LW_logo_small_6.png",
        "LW_logo_small_7.png"
      ],
      "xl": [
        "LW_logo_xl_1.png",
        "LW_logo_xl_2.png",
        "LW_logo_xl_4.png",
        "LW_logo_xl_5.png",
        "LW_logo_xl_6.png",
        "LW_logo_xl_7.png"
      ],
      "description": ""
    }
  },
  "prywatne": {
    "Darek Kuchnia": {
      "large": [
        "DarekKuch_logo_large_1.png",
        "DarekKuch_logo_large_2.png",
        "DarekKuch_logo_large_3.png",
        "DarekKuch_logo_large_4.png",
        "DarekKuch_logo_large_5.png",
        "DarekKuch_logo_large_6.png"
      ],
      "max": [
        "DarekKuch_logo_max_1.png",
        "DarekKuch_logo_max_2.png",
        "DarekKuch_logo_max_3.png",
        "DarekKuch_logo_max_4.png",
        "DarekKuch_logo_max_5.png",
        "DarekKuch_logo_max_6.png"
      ],
      "medium": [
        "DarekKuch_logo_medium_1.png",
        "DarekKuch_logo_medium_2.png",
        "DarekKuch_logo_medium_3.png",
        "DarekKuch_logo_medium_4.png",
        "DarekKuch_logo_medium_5.png",
        "DarekKuch_logo_medium_6.png"
      ],
      "mini": [
        "DarekKuch_mini_1.png",
        "DarekKuch_mini_2.png",
        "DarekKuch_mini_3.png",
        "DarekKuch_mini_4.png",
        "DarekKuch_mini_5.png",
        "DarekKuch_mini_6.png"
      ],
      "small": [
        "DarekKuch_logo_small_1.png",
        "DarekKuch_logo_small_2.png",
        "DarekKuch_logo_small_3.png",
        "DarekKuch_logo_small_4.png",
        "DarekKuch_logo_small_5.png",
        "DarekKuch_logo_small_6.png"
      ],
      "xl": [
        "DarekKuch_logo_xl_1.png",
        "DarekKuch_logo_xl_2.png",
        "DarekKuch_logo_xl_3.png",
        "DarekKuch_logo_xl_4.png",
        "DarekKuch_logo_xl_5.png",
        "DarekKuch_logo_xl_6.png"
      ],
      "description": "Jasne odcienie szarości łączące się z bielą oraz kolorem drewna. Takie wytyczne były podstawą do stworzenia projektu kuchni Pana Dariusza.\nNasz klient pragnął mieć jasną kuchnię na poddaszu, która będzie łączyła nowoczesne rozwiązania w klasycznej formie. Proste jasne fronty z czarnymi uchwytami idealnie współgrają z jasnym, drewnopodobnym laminatem, więc naszym zdaniem musiały znaleźć się w projekcie. Fartuch ochronny wykonaliśmy z białego lacobelu po to, aby nie rzucał się w oczy. Parapet połączyliśmy z blatem, dzięki czemu Pan Dariusz zyskał trochę więcej powierzchni roboczej. Całość wykonaliśmy na okuciach marki Blum.\nPrzyjemne odcienie, prostota i funkcjonalność to coś, co bardzo lubimy w naszych projektach, pomimo tego, że prostota wcale nie oznacza prostego i łatwego wykonania.\n\n"
    },
    "Darek Szafa": {
      "large": [
        "DarekSzafa_logo_large_1.png",
        "DarekSzafa_logo_large_2.png",
        "DarekSzafa_logo_large_3.png"
      ],
      "max": [
        "DarekSzafa_logo_max_1.png",
        "DarekSzafa_logo_max_2.png",
        "DarekSzafa_logo_max_3.png"
      ],
      "medium": [
        "DarekSzafa_logo_medium_1.png",
        "DarekSzafa_logo_medium_2.png",
        "DarekSzafa_logo_medium_3.png"
      ],
      "mini": [
        "DarekSzafa_mini_1.png",
        "DarekSzafa_mini_2.png",
        "DarekSzafa_mini_3.png"
      ],
      "small": [
        "DarekSzafa_logo_small_1.png",
        "DarekSzafa_logo_small_2.png",
        "DarekSzafa_logo_small_3.png"
      ],
      "xl": [
        "DarekSzafa_logo_xl_1.png",
        "DarekSzafa_logo_xl_2.png",
        "DarekSzafa_logo_xl_3.png"
      ],
      "description": "Dostaliśmy wytyczne do projektu Szafy Pana Darka takie, abyśmy wykonali ją w drewnianym deseniu oraz aby uchwyty nie były standardowe i pasowały do Szafy.\nWpadliśmy na pomysł, aby uchwyty zrobić z tego samego materiału co szafę, czyli z płyty meblowej w tym samym deseniu. Standardowa wielkość uchwytów przy takim materiale nie wchodziła w grę, więc wykonaliśmy uchwyt od ziemi do połowy szafy.\nJest praktycznie, oryginalnie, subtelnie, czyli spełnia wszystkie oczekiwania naszego klienta.\n"
    },
    "Darek łazienka": {
      "large": [
        "DarekLaz_logo_large_1.png",
        "DarekLaz_logo_large_2.png",
        "DarekLaz_logo_large_3.png",
        "DarekLaz_logo_large_4.png",
        "DarekLaz_logo_large_5.png",
        "DarekLaz_logo_large_6.png"
      ],
      "max": [
        "DarekLaz_logo_max_1.png",
        "DarekLaz_logo_max_2.png",
        "DarekLaz_logo_max_3.png",
        "DarekLaz_logo_max_4.png",
        "DarekLaz_logo_max_5.png",
        "DarekLaz_logo_max_6.png"
      ],
      "medium": [
        "DarekLaz_logo_medium_1.png",
        "DarekLaz_logo_medium_2.png",
        "DarekLaz_logo_medium_3.png",
        "DarekLaz_logo_medium_4.png",
        "DarekLaz_logo_medium_5.png",
        "DarekLaz_logo_medium_6.png"
      ],
      "mini": [
        "DarekLaz_mini_1.png",
        "DarekLaz_mini_2.png",
        "DarekLaz_mini_3.png",
        "DarekLaz_mini_4.png",
        "DarekLaz_mini_5.png",
        "DarekLaz_mini_6.png"
      ],
      "small": [
        "DarekLaz_logo_small_1.png",
        "DarekLaz_logo_small_2.png",
        "DarekLaz_logo_small_3.png",
        "DarekLaz_logo_small_4.png",
        "DarekLaz_logo_small_5.png",
        "DarekLaz_logo_small_6.png"
      ],
      "xl": [
        "DarekLaz_logo_xl_1.png",
        "DarekLaz_logo_xl_2.png",
        "DarekLaz_logo_xl_3.png",
        "DarekLaz_logo_xl_4.png",
        "DarekLaz_logo_xl_5.png",
        "DarekLaz_logo_xl_6.png"
      ],
      "description": "Po udanej realizacji kuchni Pana Darka przyszła kolej na wykonanie łazienki.\nKlasyczne połączenie bieli z drewnem, w kuchni sprawdziło się bardzo dobrze, więc spróbowaliśmy tego samego połączenia w łazience.\nPłytki w odcieniach szarości oraz naturalne światło sprawiają, że biel oraz jasne odcienie drewna bardzo dobrze się komponują.\nWyzwaniem było wkomponowanie ważnego elementu łazienki, jakim jest pralka.\nNie chcąc zabierać miejsca w łazience, stawiając pralkę bezpośrednio na podłodze, zaproponowaliśmy Panu Darkowi zabudowanie jej w kominie szafkowym pomiędzy wanną a umywalką.\nPrawda, że takie rozwiązanie nie wygląda źle ?\n"
    },
    "Karwin": {
      "large": [
        "karwin_logo_large_1.png",
        "karwin_logo_large_2.png",
        "karwin_logo_large_3.png",
        "karwin_logo_large_4.png",
        "karwin_logo_large_5.png",
        "karwin_logo_large_6.png"
      ],
      "max": [
        "karwin_logo_max_1.png",
        "karwin_logo_max_2.png",
        "karwin_logo_max_3.png",
        "karwin_logo_max_4.png",
        "karwin_logo_max_5.png",
        "karwin_logo_max_6.png"
      ],
      "medium": [
        "karwin_logo_medium_1.png",
        "karwin_logo_medium_2.png",
        "karwin_logo_medium_3.png",
        "karwin_logo_medium_4.png",
        "karwin_logo_medium_5.png",
        "karwin_logo_medium_6.png"
      ],
      "mini": [
        "karwin_mini_1.png",
        "karwin_mini_2.png",
        "karwin_mini_3.png",
        "karwin_mini_4.png",
        "karwin_mini_5.png",
        "karwin_mini_6.png"
      ],
      "small": [
        "karwin_logo_small_1.png",
        "karwin_logo_small_2.png",
        "karwin_logo_small_3.png",
        "karwin_logo_small_4.png",
        "karwin_logo_small_5.png",
        "karwin_logo_small_6.png"
      ],
      "xl": [
        "karwin_logo_xl_1.png",
        "karwin_logo_xl_2.png",
        "karwin_logo_xl_3.png",
        "karwin_logo_xl_4.png",
        "karwin_logo_xl_5.png",
        "karwin_logo_xl_6.png"
      ],
      "description": "Kuchnia Pani Marzeny z założenia miała być bardzo jasna i składać się praktycznie w całości z elementów w kolorze białym. Fronty wykonane zostały z polakierowanego MDF-u, blaty robocze z bardzo funkcjonalnego materiału, jakim jest corian oraz białego lacobelu na ścianie.\nJest to pierwsza kuchnia, którą wykonaliśmy w całości w jednym kolorze. Jesteśmy z niej bardzo zadowoleni a efekt, można zobaczyć na zdjęciach."
    },
    "Muzyk": {
      "large": [
        "muzyk_large_logo_1.png",
        "muzyk_large_logo_2.png",
        "muzyk_large_logo_3.png",
        "muzyk_large_logo_4.png",
        "muzyk_large_logo_5.png"
      ],
      "max": [
        "muzyk_max_logo_1.png",
        "muzyk_max_logo_2.png",
        "muzyk_max_logo_3.png",
        "muzyk_max_logo_4.png",
        "muzyk_max_logo_5.png"
      ],
      "medium": [
        "muzyk_medium_logo_1.png",
        "muzyk_medium_logo_2.png",
        "muzyk_medium_logo_3.png",
        "muzyk_medium_logo_4.png",
        "muzyk_medium_logo_5.png"
      ],
      "mini": [
        "muzyk_mini_1.png",
        "muzyk_mini_2.png",
        "muzyk_mini_3.png",
        "muzyk_mini_4.png",
        "muzyk_mini_5.png"
      ],
      "small": [
        "muzyk_small_logo_1.png",
        "muzyk_small_logo_2.png",
        "muzyk_small_logo_3.png",
        "muzyk_small_logo_4.png",
        "muzyk_small_logo_5.png"
      ],
      "xl": [
        "muzyk_xl_logo_1.png",
        "muzyk_xl_logo_2.png",
        "muzyk_xl_logo_3.png",
        "muzyk_xl_logo_4.png",
        "muzyk_xl_logo_5.png"
      ],
      "description": "Kolejna realizacja, którą chcielibyśmy zaprezentować, znajduje się w Krakowie na Osiedlu Kurdwanów.\nPanu Marcinowi zależało, aby jego kuchnia była prosta i funkcjonalna.\nZaproponowaliśmy sprawdzone połączenie jasnoszarej oraz drewnopodobnej płyty meblowej.\nEfekt prezentujemy poniżej na zdjęciach.\r\n\r\n\r\n\r\n\r\n"
    },
    "Wroclawska": {
      "large": [
        "wroclawska_logo_large_1.png",
        "wroclawska_logo_large_2.png",
        "wroclawska_logo_large_3.png",
        "wroclawska_logo_large_4.png"
      ],
      "max": [
        "wroclawska_logo_max_1.png",
        "wroclawska_logo_max_2.png",
        "wroclawska_logo_max_3.png",
        "wroclawska_logo_max_4.png"
      ],
      "medium": [
        "wroclawska_logo_medium_1.png",
        "wroclawska_logo_medium_2.png",
        "wroclawska_logo_medium_3.png",
        "wroclawska_logo_medium_4.png"
      ],
      "mini": [
        "wroclawska_mini_1.png",
        "wroclawska_mini_2.png",
        "wroclawska_mini_3.png",
        "wroclawska_mini_4.png"
      ],
      "small": [
        "wroclawska_logo_small_1.png",
        "wroclawska_logo_small_2.png",
        "wroclawska_logo_small_3.png",
        "wroclawska_logo_small_4.png"
      ],
      "xl": [
        "wroclawska_logo_xl_1.png",
        "wroclawska_logo_xl_2.png",
        "wroclawska_logo_xl_3.png",
        "wroclawska_logo_xl_4.png"
      ],
      "description": "Łazienka Pani Moniki nie jest duża, dlatego na samym początku tworzenia projektu ustaliliśmy wspólnie, że nie będziemy wprowadzać żadnych kolorów. Oprócz białych, lakierowanych frontów dodaliśmy elementy imitujące drewno."
    },
    "Zaleks": {
      "large": [
        "zaleks_logo_large_1.png",
        "zaleks_logo_large_2.png"
      ],
      "max": [
        "zaleks_logo_max_1.png",
        "zaleks_logo_max_2.png"
      ],
      "medium": [
        "zaleks_logo_medium_1.png",
        "zaleks_logo_medium_2.png"
      ],
      "mini": [
        "zaleks_mini_1.png",
        "zaleks_mini_2.png"
      ],
      "small": [
        "zaleks_logo_small_1.png",
        "zaleks_logo_small_2.png"
      ],
      "xl": [
        "zaleks_logo_xl_1.png",
        "zaleks_logo_xl_2.png"
      ],
      "description": "Kuchnia Pani Dagmary miała być miejscem, gdzie na co dzień szykuje się posiłki, jak i spożywa niekoniecznie w pojedynkę. Staraliśmy się więc wykorzystać blat kuchenny jako stół do spożywania posiłków. Fronty dolnych szafek wykonaliśmy z jasnej płyty drewnopodobnej natomiast górne fronty z płyty MDF polakierowanej na kolor biały. Dodatkowo zamontowana jest listwa led."
    }
  }
};
