<template>
  <div id="app">
    <Nav />
    <div class="container-fluid nopadding">
      <transition name="fade" mode="out-in" >
        <router-view />
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },

};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
#app {
  font-family: "Segoe UI Light",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: white;
}

.fontWhite {
  color: white;
}


.fontBlack {
  color: black;
}

.fontRed {
  color: red;
}

.fontGreen {
  color: #00b050;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.container-fluid.nopadding {
  padding: 0;
}

.scbtn{
    border: 4px solid #f4b414;
    background-color: transparent;
    color: #f4b414;
    padding: 10px 48px;
}
</style>
