<template>
  <div class="container">
    <div class="row">
      <div v-for="index in 20" :key="index" class="col-6" >
        <router-link :to="'/h/'+index">
        <img 
          :src="require('../assets/home'+index+'.jpg')"
          class="img-fluid mainImage"
        />
        </router-link>
        <BR/><BR/>
      </div>
    </div>

    <!-- <div class="container bal">
        <Baloons />
    </div> -->
  </div>
</template>

<script>

export default {
  name: "Home",

  data: function() {
    return {};
  },
};
</script>
<style scoped>
.bal {
  margin-top: 20px;
}

.mainImage {
  width: 100%;
  
}
</style>
