<template>
  <div class="header-bar">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg navbar-light">
        <router-link to="/" class="navbar-brand">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 488 168"
            enable-background="new 0 0 294.5 168"
            xml:space="preserve"
            class="sc_logo_top"
          >
<g > 
  <g>
    <path d="M72.67,103.601l-5.043-40.701l13.821-16.893L79.034,21.78L63.39,39.854l-0.073,0.084l2.698,22.19l-28.39,34.813
      l-0.002-0.011l-1.693,2.095l0.001,0.001l-0.348,0.432l4.816,32.966l32.174-28.736L72.67,103.601z M66.239,64.657l4.846,39.163
      L42.65,129.01l-4.734-29.621L66.239,64.657z M76.774,26.745l1.998,19.767L67.404,60.374l-2.47-20.158L76.774,26.745z"/>
    <path d="M94.33,65.679l7.01-16.307l0.001,0.004l1.208-2.821l0-0.001l-8.042-19.737l-0.736,1.535l-0.005-0.002l-8.75,18.046
      l8.016,18.92l-20.391,47.434l-1.355,3.136l22.969,32.079l1.317-2.436l18.484-32.792l0.119-0.211l0.007-0.003l-0.002-0.006
      l0.003-0.006L94.33,65.679z M86.186,46.462l7.593-15.711l6.878,16.827l-7.049,16.398L86.186,46.462z M113.002,112.459
      l-18.018,31.964l-21.525-30.194l20.295-47.21l19.25,45.43l-0.014,0.005L113.002,112.459z"/>
    <path d="M143.323,84.774l-23.076-27.29l2.223-16.671l0.171-1.222l0.007-0.115l-0.005-0.007L110.344,24.21l-3.116,19.45
      l11.911,14.109l-4.417,33.119l-0.215,1.628h0l-0.121,0.918l20.598,13.766l0.896-2.391l0.063-0.201h0l7.329-19.696L143.323,84.774z
       M108.332,43.395l2.452-15.193l10.546,13.129l-2.008,15.061L108.332,43.395z M142.139,85.017l-7.101,18.998l-19.279-12.867
      l4.304-32.283L142.139,85.017z"/>
    <polygon points="68.862,102.858 68.682,101.662 45.77,121.971 45.95,123.168    "/>
    <polygon points="96.15,136.396 96.028,136.613 96.533,137.731 110.944,112.16 111.067,111.943 110.562,110.825     "/>
    <polygon points="140.169,85.183 139.425,84.285 133.533,99.912 134.277,100.811     "/>
  </g>
  <g>
    <path d="M191.279,57.827c-0.113,0.215-0.277,0.322-0.491,0.322c-0.165,0-0.376-0.117-0.636-0.351s-0.61-0.491-1.053-0.774
      c-0.442-0.284-0.996-0.545-1.659-0.785c-0.664-0.239-1.482-0.359-2.456-0.359s-1.83,0.139-2.57,0.416s-1.359,0.656-1.859,1.135
      s-0.878,1.033-1.138,1.663s-0.389,1.292-0.389,1.985c0,0.907,0.192,1.657,0.577,2.25c0.385,0.592,0.893,1.096,1.524,1.512
      c0.631,0.416,1.347,0.766,2.148,1.049c0.801,0.284,1.625,0.564,2.471,0.842c0.845,0.277,1.669,0.583,2.47,0.917
      s1.518,0.753,2.148,1.257s1.139,1.125,1.524,1.862c0.385,0.737,0.577,1.654,0.577,2.751c0,1.121-0.192,2.177-0.577,3.166
      c-0.384,0.99-0.942,1.85-1.673,2.58c-0.731,0.731-1.626,1.309-2.685,1.73s-2.269,0.633-3.629,0.633
      c-1.765,0-3.283-0.312-4.556-0.936s-2.388-1.478-3.346-2.562l0.529-0.832c0.151-0.188,0.328-0.283,0.529-0.283
      c0.114,0,0.259,0.075,0.436,0.227s0.391,0.337,0.644,0.558s0.556,0.461,0.909,0.719c0.354,0.258,0.764,0.498,1.23,0.719
      s1.003,0.406,1.609,0.557c0.606,0.152,1.294,0.228,2.064,0.228c1.06,0,2.007-0.161,2.84-0.482c0.833-0.321,1.536-0.759,2.11-1.313
      s1.013-1.213,1.316-1.976c0.303-0.763,0.454-1.579,0.454-2.448c0-0.945-0.192-1.723-0.577-2.335
      c-0.385-0.61-0.894-1.122-1.524-1.531s-1.347-0.753-2.148-1.03s-1.625-0.548-2.47-0.813c-0.846-0.266-1.669-0.561-2.471-0.889
      s-1.518-0.746-2.149-1.258c-0.631-0.51-1.139-1.146-1.524-1.908c-0.385-0.764-0.577-1.718-0.577-2.865
      c0-0.895,0.17-1.758,0.511-2.59c0.341-0.831,0.839-1.565,1.496-2.201c0.656-0.637,1.467-1.147,2.433-1.531
      c0.965-0.385,2.066-0.577,3.303-0.577c1.388,0,2.634,0.221,3.739,0.661c1.104,0.441,2.11,1.115,3.018,2.023L191.279,57.827z"/>
    <path d="M226.29,81.325h-1.947V68.509h-16.75v12.816h-1.947v-26.75h1.947v12.478h16.75V54.575h1.947V81.325z"/>
    <path d="M265.648,67.941c0,2.067-0.309,3.941-0.926,5.624s-1.487,3.119-2.609,4.311c-1.122,1.19-2.464,2.111-4.026,2.76
      c-1.563,0.649-3.296,0.974-5.199,0.974c-1.878,0-3.598-0.324-5.161-0.974c-1.563-0.648-2.905-1.569-4.026-2.76
      c-1.122-1.191-1.995-2.628-2.618-4.311c-0.624-1.683-0.936-3.557-0.936-5.624c0-2.054,0.312-3.923,0.936-5.605
      c0.624-1.683,1.496-3.119,2.618-4.31c1.122-1.191,2.464-2.115,4.026-2.77c1.563-0.656,3.283-0.983,5.161-0.983
      c1.903,0,3.636,0.325,5.199,0.974c1.563,0.648,2.905,1.572,4.026,2.77c1.122,1.197,1.992,2.637,2.609,4.319
      S265.648,65.888,265.648,67.941z M263.645,67.941c0-1.865-0.258-3.542-0.775-5.028c-0.516-1.487-1.244-2.748-2.183-3.781
      c-0.939-1.033-2.07-1.824-3.394-2.373c-1.323-0.548-2.792-0.822-4.405-0.822c-1.588,0-3.041,0.274-4.357,0.822
      c-1.317,0.549-2.452,1.34-3.403,2.373c-0.952,1.033-1.689,2.294-2.212,3.781c-0.523,1.486-0.785,3.163-0.785,5.028
      c0,1.878,0.262,3.558,0.785,5.038s1.26,2.738,2.212,3.771c0.951,1.033,2.085,1.821,3.403,2.363
      c1.317,0.541,2.77,0.813,4.357,0.813c1.613,0,3.082-0.271,4.405-0.813c1.323-0.542,2.454-1.33,3.394-2.363
      c0.938-1.033,1.667-2.291,2.183-3.771C263.387,71.499,263.645,69.819,263.645,67.941z"/>
    <path d="M282,70.55v10.775h-1.928v-26.75h6.9c3.138,0,5.504,0.688,7.098,2.061c1.595,1.375,2.393,3.334,2.393,5.88
      c0,1.173-0.218,2.249-0.652,3.233c-0.436,0.982-1.063,1.83-1.881,2.542c-0.82,0.712-1.815,1.267-2.987,1.663
      c-1.173,0.397-2.495,0.596-3.97,0.596H282z M282,69h4.973c1.184,0,2.242-0.163,3.176-0.491c0.933-0.328,1.723-0.781,2.373-1.361
      c0.648-0.58,1.146-1.264,1.492-2.051c0.348-0.788,0.521-1.648,0.521-2.581c0-2.054-0.634-3.636-1.899-4.745
      c-1.268-1.109-3.154-1.664-5.662-1.664H282V69z"/>
    <path d="M328.939,76.826c0.126,0,0.233,0.044,0.322,0.133l0.756,0.813c-0.555,0.593-1.16,1.125-1.814,1.598
      c-0.656,0.473-1.371,0.876-2.146,1.21c-0.774,0.334-1.625,0.592-2.552,0.775c-0.927,0.182-1.944,0.273-3.053,0.273
      c-1.853,0-3.552-0.324-5.095-0.973c-1.544-0.649-2.868-1.57-3.971-2.761s-1.963-2.631-2.58-4.319
      c-0.617-1.689-0.927-3.566-0.927-5.634c0-2.029,0.318-3.882,0.955-5.558s1.531-3.116,2.685-4.32
      c1.153-1.203,2.533-2.137,4.14-2.798c1.607-0.661,3.381-0.992,5.322-0.992c0.97,0,1.858,0.073,2.665,0.218s1.56,0.353,2.259,0.623
      c0.7,0.271,1.358,0.608,1.976,1.012c0.618,0.403,1.223,0.869,1.815,1.398l-0.586,0.852c-0.102,0.15-0.259,0.227-0.473,0.227
      c-0.113,0-0.259-0.066-0.435-0.199c-0.177-0.132-0.4-0.295-0.672-0.49c-0.271-0.195-0.599-0.41-0.982-0.644
      c-0.385-0.233-0.842-0.447-1.371-0.643s-1.141-0.359-1.834-0.491c-0.692-0.133-1.48-0.199-2.362-0.199
      c-1.626,0-3.119,0.281-4.48,0.842s-2.533,1.361-3.517,2.4c-0.982,1.04-1.748,2.301-2.297,3.781
      c-0.548,1.48-0.822,3.142-0.822,4.981c0,1.89,0.271,3.579,0.813,5.066s1.292,2.744,2.25,3.771s2.089,1.814,3.394,2.362
      c1.305,0.549,2.719,0.822,4.244,0.822c0.957,0,1.812-0.063,2.562-0.188c0.749-0.126,1.439-0.315,2.07-0.567
      c0.63-0.252,1.216-0.561,1.758-0.926s1.077-0.794,1.606-1.285c0.063-0.051,0.123-0.092,0.18-0.123S328.864,76.826,328.939,76.826z
      "/>
    <path d="M359.697,54.575v1.607h-14.084v10.813h11.721v1.569h-11.721v11.153h14.084v1.606h-16.031v-26.75H359.697z"/>
    <path d="M374.934,54.642c0.101,0.045,0.208,0.143,0.321,0.293l17.316,23.178c-0.037-0.379-0.057-0.744-0.057-1.097v-22.44h1.701
      v26.75h-0.926c-0.29,0-0.523-0.125-0.699-0.377l-17.393-23.215c0.038,0.377,0.057,0.736,0.057,1.077v22.515h-1.683v-26.75h0.945
      C374.694,54.575,374.833,54.599,374.934,54.642z"/>
    <path d="M427.186,54.575v1.646h-9.396v25.104h-1.928V56.221h-9.452v-1.646H427.186z"/>
    <path d="M455.41,54.575v1.607h-14.084v10.813h11.721v1.569h-11.721v11.153h14.084v1.606h-16.031v-26.75H455.41z"/>
    <path d="M471.781,69.227v12.099h-1.929v-26.75h6.824c3.088,0,5.395,0.596,6.919,1.787c1.525,1.191,2.288,2.939,2.288,5.246
      c0,1.021-0.177,1.953-0.529,2.798c-0.354,0.845-0.86,1.585-1.522,2.222c-0.661,0.637-1.459,1.159-2.391,1.568
      c-0.934,0.41-1.985,0.678-3.157,0.804c0.302,0.188,0.573,0.441,0.813,0.757l9.207,11.568h-1.683c-0.202,0-0.375-0.037-0.521-0.113
      c-0.145-0.075-0.286-0.201-0.425-0.378l-8.563-10.851c-0.215-0.277-0.444-0.473-0.69-0.586s-0.627-0.171-1.144-0.171H471.781z
       M471.781,67.79h4.65c1.185,0,2.246-0.142,3.185-0.426c0.939-0.283,1.733-0.689,2.383-1.219c0.648-0.529,1.144-1.166,1.483-1.91
      c0.341-0.742,0.511-1.581,0.511-2.514c0-1.902-0.624-3.314-1.872-4.234c-1.247-0.92-3.063-1.381-5.444-1.381h-4.896V67.79z"/>
  </g>
  <rect x="159.48" y="54.19" width="0.5" height="27.626"/>
</g>
</svg>
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
           <!-- <li class="nav-item">
              <router-link to="/allHome" class="nav-link">allHome</router-link>
            </li> -->

            <li class="nav-item">
              <router-link to="/onas" class="nav-link">o nas</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/portfolio" class="nav-link">portfolio</router-link>
            </li>

            <li class="nav-item">
              <router-link to="/dlaarchitektow" class="nav-link"
                >dla architektów</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/kontakt" class="nav-link">kontakt</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/EN" class="nav-link">EN</router-link>
            </li>

            <!-- <li class="nav-item">
              <a href="http://facebook.com"
                ><div class="nav-link">
                  <font-awesome-icon
                    :icon="{ prefix: 'fab', iconName: 'facebook-f' }"
                  /></div
              ></a>
            </li> -->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-link,
.navbar-brand {
  text-transform: uppercase;
  color: white;
}
.nav-item {
  text-decoration: none;
  font-size: 1.3em;
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.header-bar {
  background: rgb(0, 0, 0);
}

.sc_logo_top {
  margin-top: -15px;
  margin-bottom: -15px;
  height: 16vh;
  margin-left: -30px;
  fill: rgb(255,255 , 255);
}
</style>
