export default class {
    constructor({title, description, mainImage,imgs, thumbs, galleries}) {

        
        let images = imgs.map(img=>{
            if(typeof img === 'string'){
                return {title:"", url:img};
            } else {
                return img;    
            }
        });
        let thumbnails = thumbs || images.map(img=>img.url);

        let gallery = {thumbnails, images};
        //     gallery.title = "test"
        // let galleries = [gallery];
    

      Object.assign(this, { title, description, mainImage,gallery,galleries});
    }
  }
  