<template>
  <div
    class="container"
    v-bind:class="{ 'fade-leave-active': !show, 'fade-enter-active': show }"
  >
    <div class="row d-none d-sm-block">
      <div class="col">
        <h1 class="page-title text-left">{{ title }}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-2 order-sm-last sticky-top">
        <Baloons class="sticky-top" />
      </div>
      <div class="col-xs-12 col-sm">
        <div class="row">
          <div class="row d-block d-sm-none">
            <div class="col">
              <h1 class="page-title text-left">{{ title }}</h1>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-12 order-lg-1 order-md-12 order-sm-12 order-12"
          >
            <p class="text-justify">{{ description }}</p>
          </div>
          <div
            class="col-lg-6 mainimage col-md-12 order-lg-12 order-md-1 order-sm-1 order-1"
          >
            <img
              :src="mainImg.url"
              class="img-fluid"
              v-bind:class="{
                'fade-leave-active': !mainImg.show,
                'fade-enter-active': mainImg.show,
              }"
            />

            <!-- <img :src="require('../assets/' + mainImage)" class="img-fluid" /> -->
          </div>
        </div>
        <br />
        <div>
          <LightGallery
            :images="gallery.images"
            :index="index"
            :disable-scroll="true"
            @close="index = null"
          />

          <div>
            <div v-for="gallery in galleries" :key="gallery.id">
              <div class="row">
                <div class="col">
                  <br />
                  <h3>{{ gallery.title }}</h3>
                  <div class="description">{{ gallery.description }}</div>
                </div>
              </div>
              <div class="row thumbnails">
                <div
                  class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                  v-for="(thumb, thumbIndex) in gallery.thumbnails"
                  :key="thumbIndex"
                  @click="index = thumb.index"
                >
                  <img
                    :src="thumb.url"
                    class="img-fluid img-thumbnail thumbnail"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Baloons from "@/components/Baloons.vue";

import biura from "@/data/pages/biura";
import restauracje from "@/data/pages/restauracje";
import sklepy from "@/data/pages/sklepy";
import medyczne from "@/data/pages/medyczne";
import prywatne from "@/data/pages/prywatne";

import { LightGallery } from "vue-light-gallery";

let wait = async (miliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, miliseconds);
  });
};

const pageValues = {
  biura,
  restauracje,
  sklepy,
  medyczne,
  prywatne,
};

const getPageData = (page) => {
  const pageValue = pageValues[page];
  return {
    show: true,
    title: pageValue.title,
    description: pageValue.description,
    mainImage: pageValue.mainImage,
    gallery: pageValue.gallery,
    galleries: pageValue.galleries,
    index: null,
    mainImg: {
      url:
        pageValue.gallery.images[
          Math.floor(Math.random() * pageValue.gallery.images.length)
        ].url,
      show: true,
    },
  };
};

let rotateTimeout;

export default {
  name: "Gallery",
  components: { Baloons, LightGallery },
  data: function(props) {
    return getPageData(props.page);
  },
  props: {
    page: String,
  },
  methods: {
    rotateMainImate() {
      clearInterval(rotateTimeout);
      rotateTimeout = setInterval(async () => {
        this.mainImg.show = false;
        await wait(600);
        let nextIndex = this.gallery.images.findIndex(
          (i) => i.url == this.mainImg.url
        );
        nextIndex = ++nextIndex == this.gallery.images.length ? 0 : nextIndex;
        this.mainImg.url = this.gallery.images[nextIndex].url;
        await wait(600);
        this.mainImg.show = true;
      }, 30000);
    },
  },
  created() {
    this.rotateMainImate();
  },
  watch: {
    page: {
      async handler(newValue) {
        clearInterval(rotateTimeout);
        Object.assign(this, { show: false });
        await wait(300);
        Object.assign(this, { gallery: [], galleries: [], mainImg:{show:false}});
        await wait(300);
        Object.assign(this, getPageData(newValue));
        await wait(300);
        Object.assign(this, { show: true });
        
        this.rotateMainImate();
      },
      deep: false,
    },
  },
};
</script>
<style scoped>
.mainimage {
  margin-top: 5px;
}

img.fade-enter-active,
img.fade-leave-active {
  transition-duration: 0.8s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.description {
  font-style:italic;
  font-size: 0.8rem

}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: opacity, scale;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.thumbnails div {
  height: 20vh;
  padding-bottom: 1vmin;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.thumbnail:hover {
  transform: scale(1.1);
}
</style>
